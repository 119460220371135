import axios from "axios"
import { Message } from 'element-ui'
import Utils from '../utils'
const utils = new Utils()

const request = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    // withCredentials: true, // 跨域请求时发送Cookie
    timeout: 15000 // 请求超时时间
})
// request interceptor
request.interceptors.request.use(
    (config) => {
        if (config.data && config.data.apiType == 'file') {
            config.headers['Content-Type'] = 'multipart/form-data'
        } else {
            config.headers['Content-Type'] = 'application/json;charset=UTF-8'
        }
        return config
    },
    (error) => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)
// response interceptor
request.interceptors.response.use(
    (response) => {
        const res = response.data
        if (!response.data.result) {
            utils.message(response.data.msg, 'error')
        }
        return res
    },
    (error) => {
        console.log('err' + error) // for debug
        Message({
            message: error.message,
            type: 'error',
            duration: 2 * 1000
        })
        return Promise.reject(error)
    }
)

export default request
