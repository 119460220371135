import { Message } from 'element-ui'

class Utils {
  isIos() {
    // 判断是否是Ios
    return /(iPhone|iPad|iPod|iOS|Mac)/i.test(navigator.userAgent)
  }
  isAndroid() {
    // 判断是否是Android
    return /(Android)/i.test(navigator.userAgent)
  }
  message(msg, type = 'info') {
    // 消息提示 success/warning/info/error
    if (document.getElementsByClassName('el-message').length > 0) return

    Message({
      message: msg,
      center: true,
      duration: 2000,
      type
    })
  }
}
export default Utils;
