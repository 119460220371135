import request from '../utils/request'
const baseUrl = process.env.VUE_APP_BASE_SERVER
class Api {
    // TODO 地图-省份
    getMapProvince(data) {
        return request({
            url: baseUrl + "/lsmOrg/index",
            method: "POST",
            data
        })
    }
    // TODO 地图-市、县
    getMapCity(data) {
        return request({
            url: baseUrl + "/lsmOrg/getDataByAreaCode",
            method: "POST",
            data
        })
    }
    // TODO 搜索-列表
    getMapSearch(data) {
        return request({
            url: baseUrl + "/lsmOrg/searchOrg",
            method: "POST",
            data
        })
    }
    // TODO 组织详情
    getOrgInfo(data) {
        return request({
            url: baseUrl + "/lsmOrg/getOrgInfo",
            method: "POST",
            data
        })
    }
}

export default Api
